import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `<span class="h1-span">Data-Driven </span> <span class="h1-span"> UX Research Agency</span>`,
    content:
      "A trusted user research agency driving user-centric excellence through tailor-made solutions.",
  };
  const commonContent6 = {
    title: "How Our UX Research Services Drive Business Success?",
    para: "A bad UX can pull down the growth of the business. That's why our user research company uses a data-driven approach to deliver comprehensive insights and optimize UX for long-term business success.",
    img: ["what-service-image.webp"],
    imageTitle: 'Funnel UI UX Audit',
    imageAlt: 'UX research services',
    content: [
      {
        title: "Usability Testing",
        text: "We identify the functional issues in your products to gather user feedback and improve their experience.",
      },
      {
        title: "User Interviews",
        text: "We gain insights into users’ preferences and tailor digital products based on their needs.",
      },
      {
        title: "Competitor Analysis",
        text: "We understand your competitor’s offerings to analyze market trends and form strategies accordingly.",
      },
      {
        title: "Eye Tracking",
        text: "We analyze competitors' moves to evaluate user preferences and optimize our design.",
      },
      {
        title: "Heatmaps & Click Maps",
        text: "We identify the areas of improvement with the help of visual representation of user interaction.",
      },
      {
        title: "A/B Testing",
        text: "We experiment with various design concepts to understand which design strategy better serves your purpose.",
      }
    ],
  };
  const commonContent = {
    title: "Industries We Revolutionise as User Research Agency",
    para: "The insights of our user experience research agency are beneficial for every company involved in the design, development & marketing of a product or service with a digital interface.",
    img: ["who-needs-user-image.webp"],
    imageTitle: 'User Interview',
    imageAlt: 'user research services',
    content: [
      {
        title: "Tech Solution Providers",
        text: "Companies that create end-to-end products with the help of product teams, marketing teams, and business owners.",
      },
      {
        title: "Innovative Solution Provider",
        text: "Companies that focus on discovering user pain points and preferences for simplified solutions.",
      },
      {
        title: "SaaS Companies",
        text: "Companies whose aim is to educate users about their products on a large scale and facilitate product adoption.",
      },
      {
        title: "Consumer Companies",
        text: "Companies that focus on continuous digital product improvement for enhanced customer experience.",
      },
    ],
  };

  const commonContent2 = {
    title: `Why Collaborate With <span class="h1-span">User Research Company?</span>`,
    para: "A UX consulting company understands the behavior of your target audience and develops simplified solutions to assist your business in gaining long-term growth.",
    img: ["why-work-with.webp"],
    imageTitle: 'UX Research Benefits',
    imageAlt: 'Why work with octet for ux research ',
    content: [
      {
        title: "Better Product Market Fit",
        text: "UX research helps to understand your users’ pain points, behavior, and preferences to enhance product viability.",
      },
      {
        title: "Better Decision-Making",
        text: "User research helps you make informed design and development decisions with evidence of user behavior.",
      },
      {
        title: "Identify Gaps & Reduce Risks",
        text: "UX research deepens companies' understanding of user requirements to mitigate unforeseen risks.",
      },
      {
        title: "Increased Adoption Rate",
        text: "User research yields user preference insights for creating appealing products or services and driving adoption.",
      },
      {
        title: "Competitive Advantage",
        text: "Leveraging user insights, a UX consulting company aids in competitor analysis & positioning products for long-term growth.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why Partner with Our <span class='h1-span'>UX Research Agency?</span>",
    linkText: `We are a leading user research agency offering comprehensive UX research services to deliver an impeccable combination of design and usability. Our belief in a transparent and quality-driven approach helps us exceed your expectations. `,
    img: ["why-opt-for.webp"],
    imageTitle: 'UI UX Design Process',
    imageAlt: 'why choose octet for ux research ',
    content: [
      {
        title: "Expertise and Experience",
        text: "We have a team of experienced professionals with industry expertise in user research.",
      },
      {
        title: "Customised Approach",
        text: "We customize our user research approach to deliver relevant, insightful data that aligns with your business goals.",
      },
      {
        title: "Collaborative Methods",
        text: "We involve stakeholders and end-users in the UX research process, ensuring accurate and actionable insights.",
      },
      {
        title: "Advanced Research Tools",
        text: "We use advanced user research tools to provide reliable and actionable data that drives success for your business.",
      },
      {
        title: "Comprehensive Reporting",
        text: "We present precise, visually appealing data to help you make data-driven decisions and extract valuable insights.",
      },
    ],
  };
  const commonContent4 = {
    title: "How our method sets us apart as <br /> UX Research Company?",
    para: "Our user experience research consultancy utilizes customized qualitative and quantitative techniques and an inter-professional approach to establish research goals and gain holistic user insights.",
    img: ["how-we-conduct.webp"],
    imageTitle: 'Card Sorting',
    imageAlt: 'user experience research agency',
    content: [
      {
        title: "1. Planning",
        text: "We create a detailed plan of action required to conduct user experience research and gain a thorough user understanding.",
      },
      {
        title: "4. Recruiting",
        text: "To dive deep into users’ preferences, we recruit participants who fit the target audience's requirements.",
      },
      {
        title: "2. Research",
        text: "We use various methods, such as surveys, interviews, etc., to conduct thorough research and collect data for valuable insights.",
      },
      {
        title: "5. Synthesise",
        text: "We create different hypotheses by filling in the knowledge gaps and approving or disapproving data based on the results obtained.",
      },
      {
        title: "3. Conduct Research",
        text: "We create comprehensive and visually appealing reports based on our research findings to offer relevant data insights.",
      },
    ],
  };
  const commonContent5 = {
    title: "What do you gain by <span class='h1-span'> investing in User Research Agency?</span>",
    para: "User Research Services are a long-term business investment. They identify business issues at an early design stage and improve user experiences based on preferences.",
    content: [
      {
        title: "50% Less Development Time",
        text: "UX research provides a clear product vision, which can help developers reduce the time spent on avoidable rework.",
      },
      {
        title: "56% Loyal Customers",
        text: "UX research helps understand users’ needs, improve products or services, and get loyal customers.",
      },
      {
        title: "80% User Willingness",
        text: "Investing in user research will deliver a good user experience for which customers are willing to pay extra.",
      },
      {
        title: "301% Increased ROI",
        text: "A good UX can help your business give high returns, making investing in user research important.",
      },
    ],
    desc: "**These data are from public sources, we do not claim to owe these stats.",
  };
  const cta = {
    title:
      "Let’s Track <span class='h1-span'> Your User Engagement!</span>",
  };
  const cta2 = {
    title:
      "Boost Conversions with <span class='h1-span'>Our User Insights!</span>",
  };
  const cta3 = {
    title: "Ready to Optimise Your <span class='h1-span'>User Experience With Octet?</span>",
  };
  const cta4 = {
    title: "Drive Engagement with your Users!",
  };
  const cta5 = {
    title: "Let’s Get You Started for <span class='h1-span'>Data-Driven Success?</span>",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "User Guide",
      desc: "A Customer Success Management Platform",
      Industry: "Enterprise",
      firstAlt: "User Research Case Study",
      firstTitle: "Navigation Screen",
      secondAlt: "UX research company",
      secondTitle: "Navigation Results",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Data Visualisation",
          link: "/data-visualisation/",
        },
      ],
      link: "/project/customer-success-management-platform-design/",
      images: ["user-guides.webp", "user-guides-2.webp"],
    },
    {
      title: "Alpha Test",
      desc: "A no-code test automation platform",
      Industry: "Enterprise",
      firstAlt: "UI UX No-Code Test Automation Dashboard",
      firstTitle: "Automation Dashboard",
      secondAlt: "Test Run Results of Specific Projects",
      secondTitle: "Test Run Results",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Usability Testing",
          link: "/usability-testing/",
        },
      ],
      link: "/project/alpha-test/",
      images: ["alpha-test.webp", "alpha-test-2.webp"],
    },
  ];
  const Questions = [
    "What are Octet’s capabilities in User Research?",
    "How do you decide on a user research method for our business?",
    "How do you determine the sample size of user research?",
    "What are the metrics you use to measure research success?",
    "Can you do remote User Research?",
    "Do you perform User Research for non-digital products?",
    "How much does it cost to hire your Research Service?",
    "How much time does it take to do User Research?",
    "What will be our role during the research engagement?",
    "How do you communicate your user Research Findings?",
  ];
  const clientsTitle = "Companies We've Helped In User Research Services";
  const faqDes = `We understand the queries you might face while hiring our user research services. In the following questions, we tried to give you more information about our UX consulting company. However, if you still have any questions, <a href="/contact-us/">contact us</a>.`;

  const faqData = [
    {
      para: [
        `We have extensive capabilities in user research. Being a leading <a target="_blank" rel="noreferrer" href="/">UI UX design company</a>  we offer a range of user research methods, conduct both in-person and remote research, and use a variety of tools to gather and analyze data.`,
        `Our highly skilled team uses qualitative and quantitative techniques to understand design principles better and create effective digital solutions.`,
      ],
    },
    {
      para: [
        `Octet’s user research methods depend on your business goals and objectives. As a user experience research agency, we use various research methods, such as surveys, interviews, usability testing, and analytics analysis. `,
        `Our research method uses your audience’s characteristics, demographics, and behaviors to capture their insights effectively.`,
      ],
    },
    {
      para: [
        `Being an experienced user experience research consultancy, we understand the importance of finding a relevant and suitable number of representatives for user research.`,
        `Our sample size is based on the minimal point at which we aim to achieve a desired goal. We conduct research on different batches until we achieve that result.`,
      ],
    },
    {
      para: [
        `We measure the success of the user research method by evaluating its impact on the design process and the quality of the final product. `,
        `The generalized metrics we use in every product are user satisfaction, usability, engagement, and business outcomes.`,
        `We also conduct retrospective evaluations of our user experience research methods to identify the areas of improvement. `,
      ],
    },
    {
      para: [
        `We can do remote user research based on your business objectives and requirements.`,
        `As an experienced UX research agency, we have the right set of tools and techniques to provide valuable insights into your users’ needs and behaviors to capture insights effectively.`,
      ],
    },
    {
      para: [
        `No, we do not perform user research for non-digital products. We are a UX research agency specializing in UX research for SaaS, B2B, and Enterprise-grade digital products.`,
      ],
    },
    {
      para: [
        `The cost of hiring our user research services can vary depending on the scope and complexity of the project, the experience of our designers the type of research method used, etc.`,
        `We believe in transparent pricing and provide detailed project estimates that outline all costs. To get a detailed estimate of the cost, we would recommend <a target="_blank" rel="noreferrer" href="/contact-us/">reaching out to us</a>. We would be happy to assist you.`,
      ],
    },
    {
      para: [
        `The time required to conduct user research depends on factors such as the scope of the project, research methodology, number of participants, and complexity of the research.`,
        `A standard research method may take a few days while a comprehensive study may require several months.`,
        `User research is an iterative process. Hence, we would recommend you <a target="_blank" rel="noreferrer" href="/contact-us/">contact us</a>  to discuss your project and the timeline of the research. `,
      ],
    },
    {
      para: [
        `We collaborate with you throughout the engagement process to ensure your business and research objectives are met.`,
        `This means that we iterate and improve on digital points during user research to ensure that you receive the key deliverables. `,
        `As user experience consultants, our goal is to be proactive and encourage your participation in the research process.`,
      ],
    },
    {
      para: [
        `We communicate our user experience research findings and recommendations through clear and concise reports, presentations, and prototypes that emphasize vital insights and design recommendations. `,
        `We also ensure these findings are implemented throughout the design process and provide <a target="_blank" rel="noreferrer" href="/usability-testing/">usability testing services</a> to validate design decisions.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What are Octet’s capabilities in User Research?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We have extensive capabilities in user research. Being a leading UI UX design company, we offer a range of user research methods, conduct both in-person and remote research, and use a variety of tools to gather and analyze data.
          
          Our highly skilled team uses qualitative and quantitative techniques to understand design principles better and create effective digital solutions."
              }
            },{
              "@type": "Question",
              "name": "How do you decide on a user research method for our business?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet’s user research methods depend on your business goals and objectives.
          As a user experience research agency, we use various research methods, such as surveys, interviews, usability testing, and analytics analysis. 
          
          Our research method uses your audience’s characteristics, demographics, and behaviors to capture their insights effectively."
              }
            },{
              "@type": "Question",
              "name": "How do you determine the sample size of user research?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Being an experienced user experience research consultancy, we understand the importance of finding a relevant and suitable number of representatives for user research.
          
          Our sample size is based on the minimal point at which we aim to achieve a desired goal. We conduct research on different batches until we achieve that result."
              }
            },{
              "@type": "Question",
              "name": "What are the metrics you use to measure research success?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We measure the success of the user research method by evaluating its impact on the design process and the quality of the final product.
          
          The generalized metrics we use in every product are user satisfaction, usability, engagement, and business outcomes.
          
          We also conduct retrospective evaluations of our user experience research methods to identify the areas of improvement."
              }
            },{
              "@type": "Question",
              "name": "Can you do remote User Research?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We can do remote user research based on your business objectives and requirements.
          
          As an experienced UX research agency, we have the right set of tools and techniques to provide valuable insights into your users’ needs and behaviors to capture insights effectively."
              }
            },{
              "@type": "Question",
              "name": "Do you perform User Research for non-digital products?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No, we do not perform user research for non-digital products. We are a UX research agency specializing in UX research for SaaS, B2B, and Enterprise-grade digital products."
              }
            },{
              "@type": "Question",
              "name": "How much does it cost to hire your Research Service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The cost of hiring our user research services can vary depending on the scope and complexity of the project, the experience of our designers the type of research method used, etc.
          
          We believe in transparent pricing and provide detailed project estimates that outline all costs.
          To get a detailed estimate of the cost, we would recommend reaching out to us. We would be happy to assist you."
              }
            },{
              "@type": "Question",
              "name": "How much time does it take to do User Research?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The time required to conduct user research depends on factors such as the scope of the project, research methodology, number of participants, and complexity of the research.
          
          A standard research method may take a few days while a comprehensive study may require several months.
          
          User research is an iterative process. Hence, we would recommend you contact us to discuss your project and the timeline of the research."
              }
            },{
              "@type": "Question",
              "name": "What will be our role during the research engagement?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We collaborate with you throughout the engagement process to ensure your business and research objectives are met.
          
          This means that we iterate and improve on digital points during user research to ensure that you receive the key deliverables.
          
          As user experience consultants, our goal is to be proactive and encourage your participation in the research process."
              }
            },{
              "@type": "Question",
              "name": "How do you communicate your user Research Findings?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We communicate our user experience research findings and recommendations through clear and concise reports, presentations, and prototypes that emphasize vital insights and design recommendations.
          
          We also ensure these findings are implemented throughout the design process and provide usability testing services to validate design decisions."
              }
            }]
          }        
        `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode='grey' data={cta4} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode='grey' data={cta5} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[60px] mb-[20px]  font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[880px] capitalize">
                Explore our Success Tales
              </h2>
            </div>

            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title="Creating Impact with our UX Research Services" />
          <Cta data={cta2} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} faqData={faqData} />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="UX Research Agency | Top User Research Services"
    description="A leading UX Research Company where our user experience consultant identifies your user motivations & root causes to extract valuable insights & optimize UX."
  />
);
